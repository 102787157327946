import React from "react";
import {
  FormButton,
  FormHeading,
  FormWrapper,
  DescriptionCloser,
} from "../StyledComponents";
import useModal from "../../hooks/useModal";

const QuestionModal = ({ question, title, subTitle, onSubmit }) => {
  const { closeModal } = useModal();

  return (
    <>
      <FormWrapper>
        <FormHeading title={title} description={subTitle} />
        <DescriptionCloser>{question}</DescriptionCloser>
        <section>
          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <FormButton
              style={{ width: "45%" }}
              onClick={() => {
                closeModal();
                onSubmit();
              }}
            >
              Yes
            </FormButton>
            <FormButton style={{ width: "45%" }} onClick={closeModal}>
              No
            </FormButton>
          </div>
        </section>
      </FormWrapper>
    </>
  );
};

export default QuestionModal;
