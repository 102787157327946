import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { LOGIN_URL, defaultApiHeaders } from "../../api/endpoints";
import jwtDecode from "jwt-decode";
import axios from "../../api/axios";
//import s from "./LoginModal.module.scss";
import {
  AuthForm,
  FormButton,
  FormLabel,
  TextInput,
  FormWrapper,
  FormHeading,
} from "./../StyledComponents";

import useModal from "../../hooks/useModal";
import { MODAL_REGISTER, MODAL_RESET_PASSWORD } from "../../consts/modalTypes";

const Login = () => {
  const { setAuth, auth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [isUserAuthenticating, setIsUserAuthenticating] = useState(false);

  const { closeModal, openModal } = useModal();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const onLoginSuccessfull = (response, providerType) => {
    const accessToken = response?.data?.token;
    let userData = undefined;
    if (accessToken) {
      userData = jwtDecode(accessToken);
    }

    const userId = response?.data?.userId;
    const walletConnected = response?.data?.walletConnected;
    const wallet = response?.data?.wallet;
    const email = response?.data?.wallet;
    const verified = response?.data?.verified;
    const isAddressVirtual = response?.data?.isAddressVirtual;

    setAuth({
      ...auth,
      email,
      //password: pwd,
      accessToken,
      userId,
      walletConnected,
      userData,
      ethAddress: wallet ? wallet : undefined,
      providerType,
      verified,
      isAddressVirtual,
      selectedNetwork: 1,
    });

    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUserAuthenticating(true);
    try {
      const response = await axios.post(
        LOGIN_URL,
        {
          email: user,
          password: pwd,
        },
        defaultApiHeaders
      );

      if (response) {
        onLoginSuccessfull(response, 0);
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        setErrMsg(err?.response?.data);
      } else {
        setErrMsg("Incorrect Username or Password");
      }

      errRef.current.focus();
    }
    setIsUserAuthenticating(false);
  };

  return (
    <FormWrapper>
      <FormHeading title="Sign In" description="using credentials" />

      <section>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          Sign In Failed: {errMsg}
        </p>
        <AuthForm onSubmit={handleSubmit}>
          <FormLabel htmlFor="emailname">Email</FormLabel>
          <TextInput
            type="text"
            id="emailname"
            placeholder="Email"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />

          <FormLabel htmlFor="password">Password</FormLabel>
          <TextInput
            type="password"
            id="password"
            placeholder="Password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            required
          />
          <div className="buttonsWrapper">
            <FormButton
              disabled={!pwd || !user || isUserAuthenticating}
              type="submit"
              style={{ width: "50%" }}
            >
              {isUserAuthenticating ? "Signing in" : "Sign In"}
            </FormButton>
          </div>
          <div style={{ height: "20px" }}></div>

          <div className="buttonsWrapper">
            <button
              onClick={() => openModal(MODAL_REGISTER, "/")}
              className="textButton"
            >
              Create Account
            </button>
          </div>
          <div className="buttonsWrapper">
            <button
              onClick={() => openModal(MODAL_RESET_PASSWORD, "/")}
              className="textButton"
            >
              Forgot your password ?
            </button>
          </div>
        </AuthForm>
      </section>
    </FormWrapper>
  );
};

export default Login;
