import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Modal from "./components/Modal";
import useModal from "./hooks/useModal";

function App() {
  const { isModalOpen } = useModal();
  return (
    <>
      {isModalOpen && <Modal />}

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
