import React from "react";
import "./Modal.css";
import useModal from "../hooks/useModal";
import {
  MODAL_QUESTION,
  MODAL_INFO,
  MODAL_LOGIN,
  MODAL_LOGOUT,
  MODAL_REGISTER,
  MODAL_RESET_PASSWORD,
  MODAL_CHANGE_PASSWORD,
} from "../consts/modalTypes";
import QuestionModal from "./modals/QuestionModal";
import InfoModal from "./modals/InfoModal";
import Login from "./modals/Login";
import Logout from "./modals/Logout";
import Register from "./modals/Register";
import ResetPassword from "./modals/ResetPassword";
import ChangePassword from "./modals/ChangePassword";

function Modal() {
  const { modalType, cancelModal, modalData } = useModal();

  return (
    <div className="modalBackground" /*onClick={() => cancelModal()}*/>
      <div className="modalOverlay">
        <div
          className="modalContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {modalType === MODAL_QUESTION && (
            <QuestionModal
              question={modalData.question}
              title={modalData.title}
              subTitle={modalData.subTitle}
              onSubmit={modalData.onSubmit}
            />
          )}
          {modalType === MODAL_INFO && (
            <InfoModal
              info={modalData.info}
              title={modalData.title}
              subTitle={modalData.subTitle}
            />
          )}

          {modalType === MODAL_LOGIN && <Login />}
          {modalType === MODAL_LOGOUT && <Logout />}
          {modalType === MODAL_REGISTER && <Register />}
          {modalType === MODAL_CHANGE_PASSWORD && <ChangePassword />}
          {modalType === MODAL_RESET_PASSWORD && <ResetPassword />}
        </div>
      </div>
    </div>
  );
}

export default Modal;
