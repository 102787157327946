import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwtDecode from "jwt-decode";
import axios from "../../api/axios";
import { REGISTER_URL, defaultApiHeaders } from "../../api/endpoints";
import { EMAIL_REGEX, PWD_REGEX, USER_REGEX } from "../../consts/regex";
import {
  AuthForm,
  FormButton,
  FormLabel,
  TextInput,
  FormWrapper,
  FormHeading,
} from "../StyledComponents";

import useModal from "../../hooks/useModal";

const Register = () => {
  const { setAuth, auth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const [isRegistring, setIsRegistring] = useState(false);

  const { closeModal } = useModal();

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  const onLoginSuccessfull = (response) => {
    const accessToken = response?.data?.token;
    let userData = undefined;
    if (accessToken) {
      userData = jwtDecode(accessToken);
    }

    const userId = response?.data?.userId;
    const walletConnected = response?.data?.walletConnected;
    const wallet = response?.data?.wallet;
    // just registered - email can not be verified yet
    const verified = false;
    const isAddressVirtual = response?.data?.isAddressVirtual;

    setAuth({
      ...auth,
      email: user,
      //password: pwd,
      accessToken,
      userId,
      walletConnected,
      userData,
      ethAddress: wallet ? wallet : undefined,
      verified,
      isAddressVirtual,
      selectedNetwork: 1,
    });
    setUser("");
    setPwd("");

    closeModal();

    // if (from !== "/register") {
    //   navigate(from, { replace: true });
    // } else navigate("/", { replace: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = EMAIL_REGEX.test(email);

    // if (!v1 || !v2 || !v3 || !isAuthenticated()) {
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const registrtionData = {
        email: email,
        username: user,
        password: pwd,
        moralisData: undefined,
      };

      setIsRegistring(true);
      const response = await axios.post(
        REGISTER_URL,
        registrtionData,
        defaultApiHeaders
      );

      onLoginSuccessfull(response);
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 404) {
        setErrMsg("Registration Failed. " + err?.response?.data);
      } else {
        setErrMsg("No Server Response");
      }
      errRef.current.focus();
    }
    setIsRegistring(false);
  };

  return (
    <>
      <FormWrapper>
        <FormHeading title="Sign Up" description="create account" />

        <section>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <AuthForm onSubmit={handleSubmit}>
            <FormLabel htmlFor="username">
              User Name:
              <FontAwesomeIcon
                icon={faCheck}
                className={validName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validName || !user ? "hide" : "invalid"}
              />
            </FormLabel>
            <TextInput
              type="text"
              id="username"
              placeholder="User Name"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
            />
            <div className="instructions-wrap">
              <p
                id="uidnote"
                className={
                  userFocus && user && !validName ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.
                <br />
                Must begin with a letter.
                <br />
                Letters, numbers, underscores, hyphens allowed.
              </p>
            </div>
            <FormLabel htmlFor="emailname">
              Email:
              <FontAwesomeIcon
                icon={faCheck}
                className={validEmail ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validEmail || !email ? "hide" : "invalid"}
              />
            </FormLabel>
            <TextInput
              type="text"
              placeholder="Email"
              id="emailname"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="emailnote"
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
            />
            <div className="instructions-wrap">
              <p
                id="emailnote"
                className={
                  emailFocus && email && !validEmail
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Example - name @ domain . com
              </p>
            </div>
            <FormLabel htmlFor="password">
              Password:
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !pwd ? "hide" : "invalid"}
              />
            </FormLabel>
            <TextInput
              type="password"
              id="password"
              placeholder="Password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
            <div className="instructions-wrap">
              <p
                id="pwdnote"
                className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                8 to 24 characters.
                <br />
                Must include only uppercase or lowercase letters and numbers or
                allowed special characters.
                <br />
                Allowed special characters:{" "}
                <span aria-label="underscore">_</span>{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>
            </div>
            <FormLabel htmlFor="confirm_pwd">
              Confirm Password:
              <FontAwesomeIcon
                icon={faCheck}
                className={validMatch && matchPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validMatch || !matchPwd ? "hide" : "invalid"}
              />
            </FormLabel>
            <TextInput
              type="password"
              id="confirm_pwd"
              placeholder="Confirm Password"
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby="confirmnote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
            <div className="instructions-wrap">
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the first password input field.
              </p>
            </div>

            <FormButton
              type="submit"
              disabled={
                isRegistring || !validName || !validPwd || !validMatch
                  ? true
                  : false
              }
              // disabled={
              //   !validName || !validPwd || !validMatch || !isAuthenticated()
              //     ? true
              //     : false
              // }
            >
              Sign Up
            </FormButton>
          </AuthForm>
        </section>
      </FormWrapper>
    </>
  );
};

export default Register;
