import React from "react";
import styled from "styled-components";
import closeMenu from "../assets/icons/close.png";

import useModal from "../hooks/useModal";

export const Container = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  @media only screen and (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const GeneralTitleSized = styled.h1`
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;

  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 26px;
  }
`;

export const GeneralCenteredTextSized = styled.h3`
  font-weight: 300;
  text-align: center;
  margin-bottom: 6px;

  @media only screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const NormalTextSized = styled.div`
  font-weight: 300;
  margin-bottom: 6px;
  margin-top: 10px;
  font-size: 18px;
  line-height: 20px;

  @media only screen and (max-width: 991px) {
    margin-bottom: 4px;
    margin-top: 6px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const FormWrapper = styled.div`
  width: 490px;
  min-width: 300px;
  padding: 40px 40px;
  background: rgb(197, 196, 187);
  border-radius: 10px;
  margin-top: 90px;
  box-shadow: 0 3px 3px rgb(0 0 0 / 0.3);
  border: 2px solid white;

  @media only screen and (max-width: 991px) {
    padding: 20px 20px;
    width: 350px;
    margin-top: 80px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  user-select: none;
`;

export const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;

  color: #141b26;

  @media only screen and (max-width: 991px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */

  color: #141b26;

  @media only screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 22px;
  }
`;

export const DescriptionCloser = styled(Description)`
  padding-top: 10px;
  line-height: 20px;
  @media only screen and (max-width: 991px) {
    line-height: 18px;
  }
`;

export const AuthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(197, 196, 187);
`;

export const FormLabel = styled.label`
  font-style: normal;
  font-size: 22px;

  color: rgb(105, 102, 80);

  @media only screen and (max-width: 991px) {
    font-size: 18px;
  }
`;

export const FormLabelSmall = styled(FormLabel)`
  font-size: 15px;
  margin-top: 0.3rem;
  color: darkgrey;
  text-align: center;

  @media only screen and (max-width: 991px) {
    font-size: 11px;
  }
`;

export const HistoryLabelSmall = styled(FormLabel)`
  font-size: 15px;
  margin-top: 0.1rem;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 0px;
  color: darkgrey;
  text-align: center;

  @media only screen and (max-width: 991px) {
    font-size: 11px;
    margin-top: 0.2rem;
    margin-left: 3px;
    margin-right: 2px;
    margin-bottom: 0px;
  }
`;

export const FormLabelSmallHeader = styled(FormLabelSmall)`
  font-size: 17px;
  color: #a5da3c;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  @media only screen and (max-width: 991px) {
    font-size: 13px;
  }
`;

export const FormLabelRight = styled(FormLabel)`
  text-align: right;
  width: 100%;
`;

export const TextInput = styled.input`
  padding: 10px;
  margin: 5px;
  background: white;
  border: 2px solid rgb(105, 102, 80);
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: rgba(105, 102, 80, 0.8);

  &::placeholder {
    color: rgba(105, 102, 80, 0.5);
  }

  @media only screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const FormButton = styled.button`
  border-radius: 15px;
  margin-top: 20px;
  padding: 10px;
  width: 50%;
  align-self: center;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  background-color: rgb(105, 102, 80);
  border: 2px solid white;

  :hover {
    color: rgb(105, 102, 80);
    background-color: white;
    border: 2px solid rgb(105, 102, 80);
  }

  &:disabled {
    cursor: not-allowed;
    color: rgb(99, 98, 98);
    background-color: rgb(56, 55, 43);
    border: 2px solid rgb(105, 102, 80);
  }

  @media only screen and (max-width: 991px) {
    font-size: 18px;
    line-height: 16px;
  }
`;

export const FormButtonSmall = styled.button`
  padding: 8px;
  margin: 10px;
  background: #a5da3c;
  border: 2px solid #6c9858;
  border-radius: 5px;

  font-family: "Luckiest Guy";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 15px;
  text-align: center;

  color: #ffffff;

  &:disabled {
    background: rgba(51, 51, 51, 0.5);
    border: 2px solid rgba(51, 51, 51, 0.6);
    color: rgba(51, 51, 51, 0.8);
    cursor: not-allowed;
  }

  @media only screen and (max-width: 991px) {
    padding: 7px;
    margin: 8px;
    font-size: 18px;
    line-height: 16px;
  }
`;

export const DisconnectButton = styled(FormButton)`
  background-color: #d46450;
  border-color: #d35a45;
`;

export const FormButtonWithLogo = styled(FormButton)`
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 28px;
    height: 28px;
    background-image: url(${(props) => props.icon});
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 991px) {
    &::before {
      width: 22px;
      height: 20px;
    }
  }
`;

export const CloseWrapper = styled.div`
  background-color: #821fd4;
  color: #dddddd;
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
  font-size: 30px;
  width: 45px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  box-shadow: 0px 3px 5px rgb(20 20 20 / 0.2);

  :hover {
    background-color: #ae41eef2;
    color: #ffffff;
  }

  @media only screen and (max-width: 991px) {
    border-radius: 8px;
    font-size: 18px;
    width: 34px;
    height: 28px;
    line-height: 28px;
  }
`;

export const FormHeading = function ({ className, style, title, description }) {
  const { cancelModal } = useModal();

  return (
    <Wrapper className={className} style={{ ...style }}>
      <Heading
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ marginRight: "auto", color: "rgb(20, 27, 38)" }}>
          {title}
        </div>
        <div
          onClick={() => cancelModal()}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            cursor: "pointer",
          }}
        >
          <img width="18" height="18" alt="" src={closeMenu} />
        </div>
      </Heading>
      <Description>{description}</Description>
      <div style={{ height: "20px" }}></div>
    </Wrapper>
  );
};
