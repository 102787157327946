import React from "react";
import {
  FormButton,
  FormHeading,
  FormWrapper,
  DescriptionCloser,
} from "../StyledComponents";
import useModal from "../../hooks/useModal";

const InfoModal = ({ info, title, subTitle }) => {
  const { closeModal } = useModal();

  return (
    <>
      <FormWrapper>
        <FormHeading title={title} description={subTitle} />
        <DescriptionCloser>{info}</DescriptionCloser>
        <section>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <FormButton style={{ width: "45%" }} onClick={() => closeModal()}>
              Close
            </FormButton>
          </div>
        </section>
      </FormWrapper>
    </>
  );
};

export default InfoModal;
