import React from "react";
import useAuth from "../../hooks/useAuth";
import {
  FormButton,
  FormHeading,
  FormLabel,
  FormWrapper,
} from "./../StyledComponents";
import useModal from "../../hooks/useModal";

const Logout = () => {
  const { setAuth, auth } = useAuth();
  const { closeModal } = useModal();

  const LogOut = async () => {
    setAuth({
      ...auth,
      email: undefined,
      //password: undefined,
      accessToken: undefined,
      userId: undefined,
      walletConnected: undefined,
      userData: undefined,
      ethAddress: undefined,
      providerType: undefined,
      isAddressVirtual: undefined,
      selectedNetwork: undefined,
    });
    localStorage.clear();

    closeModal("/");
  };

  return (
    <>
      <FormWrapper>
        <FormHeading title="Sing Out" description="from your account" />

        <section>
          <FormLabel>Would you like to sign out ? </FormLabel>

          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <FormButton style={{ width: "45%" }} onClick={LogOut}>
              Sign Out
            </FormButton>
            <FormButton style={{ width: "45%" }} onClick={closeModal}>
              No
            </FormButton>
          </div>
        </section>
      </FormWrapper>
    </>
  );
};

export default Logout;
