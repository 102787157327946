//export const usingDevServer = 1;
export const usingDevServer = 0;

export const apiUrl =
  usingDevServer === 0 ? "https://gochess.org:8021" : "http://localhost:8021";

export const defaultApiHeaders = {
  ClientVersion: "100.0.0",
  ClientPlatform: "WindowsEditor",
};

export const LOGIN_URL = apiUrl + "/auth/log-in/credentials";
export const REGISTER_URL = apiUrl + "/auth/register";
export const SEND_VERIFY_EMAIL_URL =
  apiUrl + "/auth/request-account-verification";
export const VERIFY_EMAIL_BY_CODE_URL = apiUrl + "/auth/verify-account/";
export const CHANGE_PASSWORD_URL = apiUrl + "/change_password";
export const RESET_PASSWORD_URL = apiUrl + "/reset_password";
export const ADD_WALLET_TO_ACCOUNT_URL = apiUrl + "/add_wallet_to_account";
