import React from "react";
import btcQrCode from "../assets/images/infinity_btc.jpg";
import ethcQrCode from "../assets/images/infinity_eth.jpg";
import dogeQrCode from "../assets/images/infinity_doge.jpg";

const Home = () => {
  return (
    <div className="mainbg">
      <div className="main-content">
        <div className="image_bg">
          <div style={{ height: "20px" }}></div>
          <div className="whiteBox" style={{ padding: "5px" }}>
            <div className="headline-big">Support me in Crypto </div>
          </div>
          <div style={{ height: "50px" }}></div>
        </div>
        <div className="colorBox" style={{ padding: "15px" }}>
          <h1 className="headlineDark">Welcome to our Crypto Donation App!</h1>

          <p className="headline-normal">
            Empower change with the ease of crypto donations! Our application
            supports a variety of coins and tokens, making it seamless for
            anyone to contribute to causes they care about.
          </p>

          <p className="headline">Key Features:</p>
          <p className="headline-normal">
            <li>
              <b>Supports Multiple Cryptocurrencies:</b> Donate with the crypto
              of your choice.
            </li>
            <li>
              <b>User-Friendly Interface:</b> An intuitive platform that
              simplifies the donation process.
            </li>
            <li>
              <b>Accessibility:</b> Open to anyone, regardless of crypto
              experience.
            </li>
            <li>
              <b>Transparency:</b> Track your donations with ease and
              transparency.
            </li>
          </p>

          <p className="headline">Building right now</p>
          <p className="headline-normal">
            We are in the development phase, focusing on creating a seamless
            experience for users. Currently, registration is open, and more
            exciting features are on the way!
          </p>

          <p className="headline-normal">
            Join us in revolutionizing the way we give. Together, let's make an
            impact.
          </p>
          <div style={{ height: "20px" }}></div>
        </div>

        <div className="colorBox" style={{ padding: "15px" }}>
          <div className="headlineDark">
            ..we are building a powerful and easy to use app for you to get all
            the crypto donations easy and fast
          </div>
          <div className="headlineDark">Meanwhile..</div>
          <div className="headlineDark">
            If you would like to support us, feel free to sent some love :-)
          </div>
          <div style={{ height: "20px" }}></div>
        </div>

        <div className="whiteBox">
          <div className="headline">Bitcoin</div>
          <div className="headline-small">
            35W3rtJDd7AATUZn6cFjmRH4nn7wS17CgG
          </div>
          {/* <div className="headline-small">
            <img src={btcQrCode} alt="btc qr code" />
          </div> */}
        </div>

        <div className="whiteBox">
          <div className="headline">Litecoin</div>
          <div className="headline-small">
            LVptQ7gaUgtaMdNBcuLmF6RQ7QrX1SWnrW
          </div>
          {/* <div className="headline-small">
            <img src={ethcQrCode} alt="eth qr code" />
          </div> */}
        </div>

        <div className="whiteBox">
          <div className="headline">Ethereum</div>
          <div className="headline-small">
            0x0beA9534Ff4f14132B3FbcB9f0DF8DA2D12547bD
          </div>
          {/* <div className="headline-small">
            <img src={ethcQrCode} alt="eth qr code" />
          </div> */}
        </div>

        <div className="whiteBox">
          <div className="headline">Doge</div>
          <div className="headline-small">
            DU7R7jDxvW6496vG3X6U9vQWrbcHqQkVwK
          </div>
          {/* <div className="headline-small">
            <img src={dogeQrCode} alt="doge qr code" />
          </div> */}
        </div>
      </div>

      <div className="headline-small">
        ..more info
        <br />
        <a
          href="mailto:michaldolomit@gmail.com"
          style={{ textDecoration: "none", color: "black" }}
        >
          michaldolomit@gmail.com
        </a>
      </div>
    </div>
  );
};

export default Home;
