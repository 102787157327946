import React from "react";
import { createContext, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ModalContext = createContext({});
const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keyup";

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState({});
  const [modalData, setModalData] = useState({});
  const [navigateTo, setNavigateTo] = useState(false);
  const [navigateToOnCancel, setNavigateToOnCancel] = useState(false);
  const navigate = useNavigate();

  const openModal = (modalType, navigateTo, modalData, navigateToOnCancel) => {
    setModalType(modalType);
    setIsModalOpen(true);
    if (navigateTo) {
      setNavigateTo(navigateTo);
    } else {
      setNavigateTo(false);
    }
    if (navigateToOnCancel) {
      setNavigateToOnCancel(navigateToOnCancel);
    } else {
      setNavigateToOnCancel(false);
    }

    setModalData(modalData);
  };

  const closeModal = (path) => {
    setIsModalOpen(false);
    if (typeof path === "string" && path) {
      navigate(path);
    } else if (navigateTo) {
      navigate(navigateTo);
      // navigate(navigateTo);
    }
    // otherwise stay on the current page
  };

  const cancelModal = useCallback(() => {
    setIsModalOpen(false);
    if (navigateToOnCancel) {
      navigate(navigateToOnCancel);
      // navigate(navigateTo);
    }
    // otherwise stay on the current page
  }, [navigateToOnCancel, navigate]);

  const handleEscKey = useCallback(
    (event) => {
      if (event.key === KEY_NAME_ESC) {
        cancelModal();
      }
    },
    [cancelModal]
  );

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        cancelModal,
        modalType,
        modalData,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
