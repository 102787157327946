import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { CHANGE_PASSWORD_URL, defaultApiHeaders } from "../../api/endpoints";
import axios from "../../api/axios";
import {
  AuthForm,
  FormButton,
  FormLabel,
  TextInput,
  FormWrapper,
  FormHeading,
} from "../StyledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { PWD_REGEX } from "../../consts/regex";
import useModal from "../../hooks/useModal";

const ChangePassword = () => {
  const errRef = useRef();

  const [oldPwd, setOldPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [isWorking, setIsWorking] = useState(false);

  const { auth } = useAuth();
  const { closeModal } = useModal();

  useEffect(() => {
    setErrMsg("");
  }, [pwd]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setIsWorking(true);
    try {
      const response = await axios.post(
        CHANGE_PASSWORD_URL,
        {
          user: auth.userId,
          password: oldPwd,
          newPassword: pwd,
          matchPassword: matchPwd,
        },
        defaultApiHeaders
      );

      if (response) {
        console.log(response);
        setSuccessMessage("Password changed successfully");
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg("Incorrect Password");
      }
      errRef.current.focus();
    }
    setIsWorking(false);
  };

  return (
    <FormWrapper>
      <FormHeading title="Change" description="your password" />

      <section>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <AuthForm onSubmit={handleSubmit}>
          {!successMessage && (
            <>
              <FormLabel htmlFor="old_password">Current Password</FormLabel>
              <TextInput
                type="password"
                id="old_password"
                placeholder="Current Password"
                onChange={(e) => setOldPwd(e.target.value)}
                value={oldPwd}
                required
              />

              <FormLabel htmlFor="password">
                New Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validPwd ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validPwd || !pwd ? "hide" : "invalid"}
                />
              </FormLabel>
              <TextInput
                type="password"
                id="password"
                placeholder="New Password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <div className="instructions-wrap">
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !validPwd ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  8 to 24 characters.
                  <br />
                  Must include only uppercase or lowercase letters and numbers
                  or allowed special characters.
                  <br />
                  Allowed special characters:{" "}
                  <span aria-label="underscore">_</span>{" "}
                  <span aria-label="exclamation mark">!</span>{" "}
                  <span aria-label="at symbol">@</span>{" "}
                  <span aria-label="hashtag">#</span>{" "}
                  <span aria-label="dollar sign">$</span>{" "}
                  <span aria-label="percent">%</span>
                </p>
              </div>
              <FormLabel htmlFor="confirm_pwd">
                Confirm New Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validMatch && matchPwd ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validMatch || !matchPwd ? "hide" : "invalid"}
                />
              </FormLabel>
              <TextInput
                type="password"
                id="confirm_pwd"
                placeholder="Confirm New Password"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <div className="instructions-wrap">
                <p
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Must match the first password input field.
                </p>
              </div>
            </>
          )}

          {successMessage && (
            <FormLabel style={{ textAlign: "center" }}>
              {successMessage}
            </FormLabel>
          )}

          <div className="buttonsWrapper">
            {successMessage ? (
              <FormButton
                style={{ width: "100%" }}
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </FormButton>
            ) : (
              <FormButton
                disabled={!oldPwd || !validPwd || !validMatch || isWorking}
                type="submit"
                style={{ width: "50%" }}
              >
                {isWorking ? "Changing" : "Change"}
              </FormButton>
            )}
          </div>
        </AuthForm>
      </section>
    </FormWrapper>
  );
};

export default ChangePassword;
