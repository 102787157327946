import React from "react";
import { createContext, useState, useEffect } from "react";
import packageInfo from "../../package.json";

const appVersion = packageInfo.version;

const AuthContext = createContext({});

const compareMajorVersion = (version) => {
  if (version) {
    const verstionParts = version.split(".");
    const appVerstionParts = appVersion.split(".");

    // if major version is the same return true
    if (appVerstionParts[0] === verstionParts[0]) {
      return true;
    }
  }
  return false;
};

const loadAuthFromStorage = () => {
  const authData = JSON.parse(localStorage.getItem("auth"));

  if (authData) {
    // if the major version is the same ( sotred / app ) then use the stored data - otherwise need logout / login so remove data :-)
    if (compareMajorVersion(authData.appVersion)) {
      //console.log("getting from storage : ", authData);
      return authData;
    }
  }
  //console.log("Nothing in storage : ", authData);
  return {};
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(loadAuthFromStorage());

  useEffect(() => {
    if (auth) {
      //      if (compareMajorVersion(auth.appVersion)) {
      auth.appVersion = appVersion;
      localStorage.setItem("auth", JSON.stringify(auth));
      //console.log("putting to storage : ", auth);
      //  }
    } else {
      //console.log("auth is cleared and trying to put to storage.. ", auth);
    }
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
