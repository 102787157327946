import React, { useRef, useState, useEffect } from "react";
import { RESET_PASSWORD_URL, defaultApiHeaders } from "../../api/endpoints";
import { EMAIL_REGEX } from "../../consts/regex";
import axios from "../../api/axios";
import {
  AuthForm,
  FormButton,
  FormLabel,
  TextInput,
  FormWrapper,
  FormHeading,
} from "../StyledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import useModal from "../../hooks/useModal";

const ResetPassword = () => {
  const errRef = useRef();
  const userEmailRef = useRef();

  const [userEmail, setUserEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [isWorking, setIsWorking] = useState(false);

  const { closeModal } = useModal();

  useEffect(() => {
    setErrMsg("");
  }, [userEmail]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(userEmail));
  }, [userEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setIsWorking(true);
    try {
      const response = await axios.post(
        RESET_PASSWORD_URL,
        {
          email: userEmail,
        },
        defaultApiHeaders
      );

      console.log(response?.data);
      if (response?.data) {
        if (response.data.success) {
          setSuccessMessage(response.data.message);
        } else {
          setErrMsg(response.data.errMessage);
        }
      } else {
        setErrMsg("Reset error.");
      }
    } catch (error) {
      console.log("error");
      console.log(error);
      setErrMsg("Account does not exits.");
      errRef.current.focus();
    }
    setIsWorking(false);
  };

  return (
    <FormWrapper>
      <FormHeading title="Reset" description="your password" />

      <section>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <AuthForm onSubmit={handleSubmit}>
          {!successMessage && (
            <>
              <FormLabel htmlFor="emailname">Email</FormLabel>
              <TextInput
                type="text"
                id="emailname"
                placeholder="Email"
                ref={userEmailRef}
                autoComplete="off"
                onChange={(e) => setUserEmail(e.target.value)}
                value={userEmail}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <div className="instructions-wrap">
                <p
                  id="emailnote"
                  className={
                    emailFocus && userEmail && !validEmail
                      ? "instructions"
                      : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Example - name @ domain . com
                </p>
              </div>
            </>
          )}

          {successMessage && (
            <FormLabel style={{ textAlign: "center" }}>
              {successMessage}
            </FormLabel>
          )}

          <div className="buttonsWrapper">
            {successMessage ? (
              <FormButton
                style={{ width: "100%" }}
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </FormButton>
            ) : (
              <FormButton
                disabled={!validEmail || isWorking}
                type="submit"
                style={{ width: "50%" }}
              >
                {isWorking ? "Working" : "Reset"}
              </FormButton>
            )}
          </div>
        </AuthForm>
      </section>
    </FormWrapper>
  );
};

export default ResetPassword;
